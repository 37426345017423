import React, { useMemo } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Link } from "gatsby"
import Layout from '../components/layout';

function Terms() {
  return (
    <Layout>

      <Typography>
        Last updated: April 1, 2024
      </Typography>
      <Typography component="h1">
      Forest Joker LLC
      </Typography>
      <Typography component="h2">
        Terms and Conditions of Sale and Disclaimers
      </Typography>

      <Typography>
      The following terms and conditions for all current uses of this website and disclaimers ("Terms") 
      constitute a binding agreement between you and Forest Joker LLC (the "Site", "we", "us", or "our"), (30 N Gould St, STE 4000, Sheridan, WY 82801, United States).
      </Typography>
      <Typography>
      PLEASE READ THE TERMS CAREFULLY BEFORE BROWSING OR MAKING A PURCHASE FROM THIS WEBSITE ("USE"). 
      THE TERMS MAY HAVE CHANGED SINCE YOUR LAST VISIT TO THIS SITE. 
      YOUR CURRENT USE OF THIS WEBSITE CONFIRMS YOUR UNCONDITIONAL ACCEPTANCE OF THE TERMS FOR YOUR CURRENT USE. 
      ONLY THE TERMS IN EFFECT AS OF A USE SHALL GOVERN FOR THAT PARTICULAR USE. 
      EACH TIME YOU MAKE USE OF THE SITE, REVIEW THE TERMS AGAIN IN ORDER TO ENSURE THAT YOU ARE AWARE OF AND ACCEPT THE THEN-CURRENT TERMS. 
      IF YOU DO NOT ACCEPT THE TERMS, DO NOT USE THIS WEBSITE. 
      You should print a copy of the Terms for future reference, since Terms may have changed since your last Use.
      </Typography>
      <Typography component="h2">
        GENERAL
      </Typography>
      <Typography>
      These Terms shall govern your current Use of this website.  These Terms supersede all terms and conditions that may have been published previously on this site.
If you are not 18 years old, please ask your parents or other adults whether you are of age to use this website and to order goods and/or services from it. There may be local laws that make it inappropriate for you to use this site or make purchases from it. You and the adults whom you must consult are solely responsible for determining if you may use this site lawfully.
These Terms are subject to change at any time without notice.
You acknowledge that you do not rely on any representation and/or warranty that has not been made in accordance with the Terms.
our attempts to ensure that information on this website is complete, accurate and current. Despite our efforts, the information on this website may occasionally be inaccurate, incomplete, or out of date.
Descriptions, depictions, representations, and/or specifications for the products and/or services on offer at this website are intended as a guide only and only give a general approximation of the efficacy of the products and/or services.
You shall use this website in a legal, honest, and inoffensive manner, in compliance with generally accepted internet usage practice from time to time, and in compliance with the prevailing standards of your own community.
You shall not use this website and any service made available to you via this website to attempt to gain access to and/or interfere with the functioning, operation or security of any network and/or system or in order to monitor a third party's use of the internet.
This website may offer online entertainment services that are adult in nature. It may contain graphic depictions and descriptions of explicit sexual activity and/or sell adult products. 
You acknowledge that you are aware of the nature of the content on this site, that you are not offended by it, and that you access this site voluntarily.
These Terms are organized under titles in order to group information into "user-friendly" categories. The titles are not to be used for purposes of interpreting the Terms.
      </Typography>
      <Typography component="h2">
        ORDERS
      </Typography>
      <ul>
      <li>We have the right to refuse to accept any orders placed for our products and services. Your order for products shall only be binding on us when you receive confirmation that a product has shipped. If we refuse your order, your money will be returned in full.</li>
      <li>You are responsible for the accuracy of all information you provide that is necessary for us to process your order and/or ship your order to the correct location. If an order has not been shipped, or shipped to an incorrect location due to such inaccuracy, you alone are responsible.</li>
      <li>The provision of products and services is subject to availability. If products are out of stock, we will inform you as soon as possible. You will be given the option of waiting until the products are in stock or of canceling your order.</li>
      <li>If you have any problems or concerns regarding our products, you can reach us via the customer service contact information supplied on this website.</li>
      <li>Dates for delivery are estimates only and are not guaranteed. Time is not of the essence in relation to such dates. They are also subject to any matter beyond our reasonable control.</li>
      <li>If a delivery requires a signature to confirm receipt, it is your responsibility to ensure that there is someone at the delivery address to sign for the products when delivered. 
        It is also your responsibility to collect the products from any postal collection depot and/or arrange an alternative delivery date if you are not available to sign for the products.</li>
      <li>Any products purchased are for personal use only. The products cannot be resold and cannot be given to any third party without our permission.</li>
      <li>You hereby agree that any resale of goods you purchase from this site constitutes trademark and copyright infringement and is not protected under the “first-sale” doctrine because of the “material difference” and “quality controls” exceptions to the “first-sale” doctrine.</li>
      <li>You further agree that resale by you makes you automatically liable for a $5,000.00 damages payment (five thousand U.S. Dollars) to Forest Joker LLC, which is enforceable in any court of competent jurisdiction. 
        This is not intended as a penalty but rather as an estimation of harm that is difficult to gauge precisely (liquidated damages). 
        There is equal bargaining power between us because this is not a product that you are obligated to purchase, and you are put on clear notice prior to purchasing the product.</li>
      <li>We are not responsible for safe delivery of goods because we do not ship the products directly. If the products are not delivered, arrive late, or are damaged in transit, you must take this up with the carrier. We will provide you with their contact details upon request.</li>
      <li>Payment is due at the time you place your order.</li>
      <li>We do not accept your order until we receive notice from our credit card merchant that your payment has been authorized and/or we have received payment in full in cleared funds.</li>
      <li>If for any reason payment in full is not made, we may withhold or suspend delivery of the products.</li>
      </ul>
      <Typography component="h2">LIABILITY</Typography>
      <ul>
        <li>The word "Liability," as used in the Terms, means any and all damages, claims, proceedings, actions, awards, expenses, costs and other losses</li>
        <li>FOREST JOKER LLC MAKES NO WARRANTIES FOR THE PRODUCTS SOLD ON THIS WEBSITE. FOREST JOKER LLC MAKES NO REPRESENTATION ABOUT THE SUITABILITY OF THE PRODUCTS ON THIS SITE FOR ANY PURPOSE. 
          ALL SUCH PRODUCTS ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. FOREST JOKER LLC SPECIFICALLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND WITH RESPECT TO THE PRODUCTS ON THIS SITE, 
          INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.</li>
        <li>THE REPRESENTATIONS, VIEWS, OPINIONS, STATEMENTS, AND RECOMMENDATIONS ON THIS SITE ARE BASED ON REPRESENTATIONS AND CONCLUSIONS BY THIRD PARTIES INCLUDING THIRD-PARTY SCIENTIFIC AND MEDICAL RESEARCHERS, 
          CUSTOMERS PROVIDING TESTIMONIALS, EXPERT ENDORSERS, INFORMATION FROM SUPPLIERS, PARTICIPANTS ON INTERNET FORUMS, AND AUTHORS WHO PUBLISH LITERATURE ABOUT THE PRODUCTS' INGREDIENTS AND THEIR EFFICACY. 
          FOREST JOKER DOES NOT ITSELF REPRESENT OR ENDORSE THE ACCURACY, CURRENTNESS, OR RELIABILITY OF ANY ADVICE, OPINION, STATEMENT, OR OTHER INFORMATION DISPLAYED, UPLOADED, OR DISTRIBUTED THROUGH THE SITE, 
          AND EXPRESSLY DISCLAIMS LIABILITY FOR ALL SUCH REPRESENTATIONS. YOU ACKNOWLEDGE THAT ANY RELIANCE ON ANY SUCH OPINION, 
          ADVICE, STATEMENT, OR INFORMATION WILL BE AT YOUR SOLE RISK. THE MATERIALS ON THIS SITE ARE TRANSMITTED AND DISTRIBUTED "AS IS" AND APPEAR ON THE SITE WITHOUT EXPRESS OR IMPLIED WARRANTIES OF ANY KIND.</li>
        <li>WE SHALL NOT BE LIABLE FOR ANY DAMAGE TO, OR ANY VIRUSES THAT MAY INFECT, YOUR COMPUTER, TELECOMMUNICATION EQUIPMENT, OR OTHER PROPERTY CAUSED BY OR ARISING FROM YOUR ACCESS TO, USE OF, OR BROWSING THIS WEBSITE, OR YOUR DOWNLOADING OF ANY INFORMATION OR MATERIALS FROM THIS WEBSITE.</li>
        <li>WE DISCLAIM ALL LIABILITY FOR PRODUCT DEFECT OR FAILURE, CLAIMS THAT ARE DUE TO NORMAL WEAR, PRODUCT MISUSE, ABUSE, PRODUCT MODIFICATION, IMPROPER PRODUCT SELECTION, NON-COMPLIANCE WITH ANY CODES, OR MISAPPROPRIATION.</li>
        <li>WE DISCLAIM ALL LIABILITY FOR REPRESENTATIONS AND WARRANTIES MADE ON OTHER WEBSITES ABOUT THE PRODUCTS AND SERVICES ON OFFER AT THIS WEBSITE IF SUCH OTHER REPRESENTATIONS AND WARRANTIES ARE INCONSISTENT WITH REPRESENTATIONS AND WARRANTIES MADE ON THIS WEBSITE.</li>
        <li>YOU HEREBY AGREE TO INDEMNIFY AND HOLD HARMLESS FOREST JOKER LLC AND ITS OFFICERS, DIRECTORS, EMPLOYEES, SUCCESSORS, AGENTS, DISTRIBUTORS, 
          AND AFFILIATES FOR ANY AND ALL CLAIMS, DAMAGES, LOSSES, AND CAUSES OF ACTION (INCLUDING ATTORNEYS' FEES) 
          ARISING OUT OF OR RELATING TO YOUR BREACH OR ALLEGED BREACH OF ANY OF THESE TERMS</li>
        <li>THESE DISCLAIMERS AND EXCLUSIONS APPLY TO ALL CONTENT, MERCHANDISE, AND SERVICES AVAILABLE ON OR THROUGH THIS SITE.</li>
        <li>THE FOREGOING LIMITATIONS OF LIABILITY DO NOT APPLY TO THE EXTENT PROHIBITED BY LAW. PLEASE REFER TO YOUR LOCAL LAWS FOR ANY SUCH PROHIBITIONS.</li>
      </ul>
      <Typography component="h2">INTELLECTUAL PROPERTY</Typography>
      <Typography>
      By virtue of copyright, trademark, international treaties, and other laws, the entire contents of this website are owned by Forest Joker LLC, except for trademarks belonging to third parties. You may not forward or copy any portion of this for any purpose other than your own personal reference.
      <br/>
      Certain trademarks, trade names, service marks, graphics, photographs, and logos used or 
      displayed on this website are registered and unregistered trademarks, trade names, service marks, graphics, photographs, or logos belonging solely to Forest Joker LLC or its subsidiaries. 
      In addition, the arrangement and design of these elements on our website, and the underlying source code, are the sole property of Forest Joker LLC. 
      You are forbidden from copying or using these in any manner without the written permission of Forest Joker LLC or other owner.
      </Typography>
      <Typography component="h2">RETURNS/REFUNDS POLICY</Typography>
      <Typography>
        <ul>
          <li>We are confident that you will be satisfied with the products you have ordered. As a result, we offer you the return and refund policy indicated on the website. In order to qualify for a refund, you must provide us all the information we ask for, and comply with all other terms specified on this website for such purpose.</li>
          <li>Refunds will be for the purchase price, but not necessarily for shipping and handling charges.</li>
          <li>You are responsible for the duties and taxes on your order, and we cannot issue a refund if you fail to pay those.</li>
          <li>To claim your refund under this guarantee, please contact support@forestjoker.com</li>
        </ul>
      </Typography>
      <Typography component="h2">CUSTOMER SERVICE</Typography>
      <Typography>
      If you are unhappy with any aspects of the services and/or products that we have provided to you or have any queries, feedback or comments regarding the service, please email us at support@forestjoker.com or call us at +1 (307) 285-9200;
      </Typography>
      <Typography
        sx={{
          textAlign: "center"
        }}>
        <Link to="/">Click to Go back Home</Link>
      </Typography>

    </Layout>
  )
}

export default Terms;